import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';
export class Home extends Component {
    static displayName = Home.name;

    cssCentered = {
        position: 'fixed',
        top: '50%',
        left: '50%'
    };

    constructor(props) {
        super(props);
        this.state = {
            medicoes: [],
            loading: true,
            meterData: [],
            modalLoading: false,
            modalShow: false,
            meterDescription: undefined,
            serial: undefined,
            detailCount: 0
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.getMedicoesData();
    }

    toggleModal() {
        this.setState({ modalShow: !this.state.modalShow, modalLoading: !this.state.modalLoading });
        if (!this.state.modalShow) {
            this.setState({ meterData: [] });
        }
    };

    prepareModal(rowData) {
        this.getMedicoesData(rowData.serial);
        this.setState({ serial: rowData.serial, meterDescription: rowData.descricao, modalShow: true, modalLoading: true, detailCount: 0 });
    }

    renderMedicoes() {
        const dateOptions = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false,
            timeZone: 'UTC'
        };

        let retDef = <>
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Descricao</th>
                        <th>Modelo</th>
                        <th>Serial</th>
                        <th>Data 1</th>
                        <th>Volume 1</th>
                        <th>Data 2</th>
                        <th>Volume 2</th>
                        <th>Data 3</th>
                        <th>Volume 3</th>
                        <th>Alarme</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.medicoes.map((medicoes) => (
                        <tr key={medicoes.row} onClick={() => this.prepareModal(medicoes)} style={{ cursor: 'pointer' }}>
                            <td>{medicoes.descricao}</td>
                            <td>{medicoes.modelo}</td>
                            <td>{medicoes.serial}</td>
                            <td>{new Intl.DateTimeFormat('pt-BR', dateOptions).format(new Date(medicoes.data1))}</td>
                            <td>{new Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                            }).format(medicoes.volume1)}</td>
                            <td>{new Intl.DateTimeFormat('pt-BR', dateOptions).format(new Date(medicoes.data2))}</td>
                            <td>{new Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                            }).format(medicoes.volume2)}</td>
                            <td>{new Intl.DateTimeFormat('pt-BR', dateOptions).format(new Date(medicoes.data3))}</td>
                            <td>{new Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                            }).format(medicoes.volume3)}</td>
                            <td>{medicoes.alarme}</td>
                        </tr>))}
                </tbody>
            </table>
            <Modal isOpen={this.state.modalShow} toggle={() => this.toggleModal()} size={"xl"} >
                <ModalHeader toggle={() => this.toggleModal()}>Ultimas Medições do Medidor: {this.state.meterDescription} / {this.state.serial}</ModalHeader>
                <ModalBody>
                    <div style={{ height: '70vh', overflowY: 'scroll' }}>
                        {(this.state.modalLoading) ?
                            <Spinner color="primary" style={this.cssCentered} /> :
                            <table className='table table-striped' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                        <th>Data 1</th>
                                        <th>Volume 1</th>
                                        <th>Data 2</th>
                                        <th>Volume 2</th>
                                        <th>Data 3</th>
                                        <th>Volume 3</th>
                                        <th>Alarme</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.meterData.map((medicoes) => (
                                        <tr key={"modal" + medicoes.row.toString()}>
                                            <td>{new Intl.DateTimeFormat('pt-BR', dateOptions).format(new Date(medicoes.data1))}</td>
                                            <td>{new Intl.NumberFormat("pt-BR", {
                                                minimumFractionDigits: 3,
                                                maximumFractionDigits: 3
                                            }).format(medicoes.volume1)}</td>
                                            <td>{new Intl.DateTimeFormat('pt-BR', dateOptions).format(new Date(medicoes.data2))}</td>
                                            <td>{new Intl.NumberFormat("pt-BR", {
                                                minimumFractionDigits: 3,
                                                maximumFractionDigits: 3
                                            }).format(medicoes.volume2)}</td>
                                            <td>{new Intl.DateTimeFormat('pt-BR', dateOptions).format(new Date(medicoes.data3))}</td>
                                            <td>{new Intl.NumberFormat("pt-BR", {
                                                minimumFractionDigits: 3,
                                                maximumFractionDigits: 3
                                            }).format(medicoes.volume3)}</td>
                                            <td>{medicoes.alarme}</td>
                                        </tr>))}
                                </tbody>
                            </table >
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="clearfix col-11">
                        <span>Total de Medições nos Últimos 6 meses: {this.state.detailCount}</span>
                    </div>
                    <Button color="primary" onClick={() => this.toggleModal()}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </>;

        return (retDef);
    }

    render() {
        let contents = (this.state.loading)
            ? <Spinner color="primary" style={this.cssCentered} />
            : this.renderMedicoes();
        return (
            <div>
                <h3>Ultimas Medições por Hidrômetro</h3>
                {contents}
            </div>
        );
    }

    async getMedicoesData(serial) {
        const token = await authService.getAccessToken();
        const usr = await authService.getUser();
        let endPoint = `medicoes?UserName=${usr.name}` + ((serial !== undefined) ? `&serial=${serial}` : '');
        fetch(endPoint, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.status);
        }).then((data) => {
            if (serial === undefined) {
                this.setState({ medicoes: data, loading: false });
            }
            else {
                this.setState({
                    meterData: data,
                    modalLoading: false,
                    detailCount: data.length
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }
}
